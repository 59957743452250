// Portfolio.js
import React from 'react';


import '/Users/irvinglamadrid/MayPortfolio/may-portfolio/src/components/z.other-components/4-MarioTheme/current.css';



function Black() {

  return (
    <div className="bg-gray-900 w-full overflow-x-hidden"> 
       
    
    </div>
  );
}

export default Black;
