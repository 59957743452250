// Portfolio.js
import React from 'react';

import '/Users/irvinglamadrid/MayPortfolio/may-portfolio/src/components/z.other-components/2-LooseLeaf-Portfolio/loose.css';


function loose() {
  return (
    <div className='h-screen body-erase'> 
       
    </div>
  );
}

export default loose;
