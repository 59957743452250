// Portfolio.js
import React from 'react';

import '/Users/irvinglamadrid/MayPortfolio/may-portfolio/src/components/z.other-components/4-MarioTheme/current.css';


function Black() {
  return (
    <div className="body4"> 
        
    </div>
  );
}

export default Black;
